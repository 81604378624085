import React from 'react';
import Layout from '../../components/Layout/Layout';
import styles from './Contact.module.css'
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { sendData } from '../../utils/fetchData';
import showModal from '../../utils/modalFunction';
import { useDispatch } from 'react-redux';


const Contact = () => {
    const userDetails = useSelector(state => state.user.userDetails);
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.target;
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());
        if (data.message.length < 30) {
            showModal(dispatch, 'Eroare', 'Mesajul trebuie sa contina cel putin 30 de caractere.')
            return;
        }

        sendData('/contact/create', JSON.stringify({ nume: userDetails.nume, prenume: userDetails.prenume, email: userDetails.email, nr_telefon: userDetails.nr_telefon, mesaj: data.message })).then(showModal(dispatch, 'Multumim', 'Mesajul dumneavoastra a fost trimis.'), form.reset())
    }
    return (
        <Layout>
            <div className={styles.contactContainer}>
                <h1 className='page_title'>Contacteaza-ne</h1>
                <div className={styles.content}>
                    <div className={styles.info}>
                        <div style={{ alignSelf: 'center', marginBottom: '25px' }}>
                            <img src="/logo.png" alt="Logo" className={styles.headerImage} />
                        </div>
                        <div className={styles.infoItem}>
                            <p style={{ fontSize: '17px' }}>
                                Pentru <strong style={{ color: '#C52E3F' }}>sugestii</strong>,{' '}
                                <strong style={{ color: '#C52E3F' }}>informatii</strong> sau{' '}
                                <strong style={{ color: '#C52E3F' }}>ajutor</strong>, contacteaza-ne la una din modalitatile de mai jos sau folosind formularul de contact.{' '}
                                <br />
                                <strong>Multumim!</strong>
                            </p>
                        </div>
                        <div className={styles.infoItem}>
                            <Icon icon="ph:phone" color='black' inline='true' height='25px' />
                            <a href="tel:+40123456789" className={styles.infoText}>+40 123 456 789</a>
                        </div>
                        <div className={styles.infoItem}>
                            <Icon icon="mdi:account-outline" color='black' inline='true' height='25px' />
                            <a href="mailto:contact@gepeto.com" className={styles.infoText}>contact@gepeto.com</a>
                        </div>
                        <div className={styles.infoItem}>
                            <Icon icon="mdi:address-marker-outline" color='black' inline='true' height='25px' />
                            <p className={styles.infoText}>Strada Exemplu, Nr. 10, Bucuresti, Romania</p>
                        </div>

                    </div>
                    <div className={styles.formContainer}>
                        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                            <div className={styles.formGroup}>
                                <label className={styles.label} htmlFor="name">Nume</label>
                                <input type="text" id="name" name="nume" className={styles.input} readOnly={true} value={`${userDetails.nume} ${userDetails.prenume}`} />
                            </div>
                            <div className={styles.formGroup}>
                                <label className={styles.label} htmlFor="email">Email</label>
                                <input type="email" id="email" name="email" className={styles.input} value={userDetails.email} readOnly={true} />
                            </div>
                            <div className={styles.formGroup}>
                                <label className={styles.label} htmlFor="telefon">Telefon</label>
                                <input type="phone" id="telefon" name="telefon" className={styles.input} readOnly={true} value={userDetails.nr_telefon} />
                            </div>
                            <div className={styles.formGroup}>
                                <label className={styles.label} htmlFor="message">Mesaj</label>
                                <textarea id="message" name="message" className={styles.textarea}></textarea>
                            </div>
                            <button type="submit" className={styles.button}>Trimite</button>
                        </form>
                    </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2855.5058461341205!2d28.551634777323844!3d44.29955041038628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40baf47b12b59679%3A0xc44fde9ae0156deb!2sGEPETO!5e0!3m2!1sen!2sro!4v1722182252380!5m2!1sen!2sro"
                    style={{ border: '0', width: '90vw', height: '25rem' }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

        </Layout>

    )
}

export default Contact

