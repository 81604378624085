import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isUserConnected: false,
    userDetails: {
        
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserStatus: (state, action) => {
            state.isUserConnected = action.payload;
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
        },
        deleteUserDetails: (state) => {
            state.userDetails = initialState.userDetails;
        },
        logOutUser: (state) => {
            state.isUserConnected = false;
            state.userDetails = initialState.userDetails;
        }
    },
});

export const { setUserDetails, setUserStatus, deleteUserDetails, logOutUser } = userSlice.actions;
export default userSlice.reducer;