import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import Product from '../../components/Product/Product';
import styles from './Produse.module.css';
import { fetchData, getDataGraphQL } from '../../utils/fetchData';
import { getProductsWithFilters } from '../../utils/graphQL/productQueries';
import { getSubCategoriesByCategoryId } from '../../utils/graphQL/categoryQueries';
import { hideLoading, showLoading } from '../../features/ui/uiSlice';
import showModal from '../../utils/modalFunction';
import Pagination from '../../components/Pagination/Pagination';

const Produse = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { tip_produs } = useParams();
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [beCategories, setBeCategories] = useState([]);
    const [beSubcategories, setBeSubcategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [oferta, setOferta] = useState(false);
    const [sortOrder, setSortOrder] = useState('desc');

    const fetchCategories = useCallback(async () => {
        try {
            const data = await fetchData(`/categorie?where[tip_produs][equals]=${tip_produs}`, dispatch);
            setBeCategories(data.docs);
        } catch (error) {
            showModal(dispatch, 'A intervenit o eroare: ', error);
            dispatch(hideLoading());
        }
    }, [dispatch, tip_produs]);

    const fetchSubcategories = useCallback(async () => {
        if (selectedCategories.length === 0) {
            setBeSubcategories([]);
            return;
        }
        try {
            dispatch(showLoading());
            const searchParameters = { categoryIds: selectedCategories };
            const subcategories = await getDataGraphQL(getSubCategoriesByCategoryId, searchParameters);
            setBeSubcategories(subcategories.data.Subcategories.docs);
            dispatch(hideLoading());
        } catch (error) {
            showModal(dispatch, 'A intervenit o eroare: ', error);
            dispatch(hideLoading());
        }
    }, [dispatch, selectedCategories]);

    const fetchProducts = useCallback(async (page = 1) => {
        try {
            dispatch(showLoading());

            const whereClause = {
                tipul_produsului: { in: [tip_produs] },
                categorie: { in: selectedCategories },
                subcategorie: { in: selectedSubcategories }
            };

            if (oferta) {
                whereClause.tip_oferta = { not_equals: "none" };
            }

            const searchParameters = {
                where: whereClause,
                page,
                limit: 12,
                sort: sortOrder === 'asc' ? 'pret_final' : '-pret_final'
            };

            const data = await getDataGraphQL(getProductsWithFilters, searchParameters);
            const { docs, ...paginationData } = data.data.Produses;
            setProducts(docs);
            setPagination(paginationData);
        } catch (error) {
            showModal(dispatch, 'A intervenit o eroare: ', error);
        } finally {
            dispatch(hideLoading());
        }
    }, [dispatch, selectedCategories, selectedSubcategories, tip_produs, oferta, sortOrder]);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    useEffect(() => {
        if (selectedCategories.length > 0) {
            fetchSubcategories();
        } else {
            setBeSubcategories([]);
        }
    }, [selectedCategories, fetchSubcategories]);

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchProducts();
        }, 0);
        return () => clearTimeout(timer);
    }, [fetchProducts]);

    useEffect(() => {
        const categoriesFromURL = searchParams.getAll('categorie');
        const subcategoriesFromURL = searchParams.getAll('subcategorie');
        const ofertaFromURL = searchParams.get('oferta');
        const sortFromURL = searchParams.get('sort');

        setOferta(ofertaFromURL === 'true');
        setSelectedCategories(categoriesFromURL);
        setSelectedSubcategories(subcategoriesFromURL);

        if (sortFromURL) {
            setSortOrder(sortFromURL);
        } else {
            // If sort is not in URL, set it to 'desc' and update URL
            const newParams = new URLSearchParams(searchParams);
            newParams.set('sort', 'desc');
            setSearchParams(newParams);
        }
    }, [searchParams, setSearchParams]);

    const handlePageChange = (newPage) => {
        fetchProducts(newPage);
    };

    const handleCategoryClick = (categoryId) => {
        const newSelectedCategories = selectedCategories.includes(categoryId.toString())
            ? selectedCategories.filter(catId => catId !== categoryId.toString())
            : [...selectedCategories, categoryId.toString()];

        setSelectedCategories(newSelectedCategories);
        setOferta(false);

        const params = new URLSearchParams(searchParams);
        params.delete('categorie');
        params.delete('oferta');
        newSelectedCategories.forEach(catId => params.append('categorie', catId));

        if (newSelectedCategories.length === 0) {
            params.delete('subcategorie');
            setSelectedSubcategories([]);
        }

        setSearchParams(params);
    };

    const handleSubcategoryClick = (subcategoryId) => {
        const newSelectedSubCategories = selectedSubcategories.includes(subcategoryId.toString())
            ? selectedSubcategories.filter(subcatId => subcatId !== subcategoryId.toString())
            : [...selectedSubcategories, subcategoryId.toString()];

        setSelectedSubcategories(newSelectedSubCategories);
        setOferta(false);

        const params = new URLSearchParams(searchParams);
        params.delete('subcategorie');
        params.delete('oferta');
        newSelectedSubCategories.forEach(subcatId => params.append('subcategorie', subcatId));
        setSearchParams(params);
    };

    const handleOfertaClick = () => {
        const params = new URLSearchParams(searchParams);
        const currentOfertaState = params.get('oferta') === 'true';

        if (currentOfertaState) {
            params.delete('oferta');
        } else {
            params.set('oferta', 'true');
        }

        params.delete('categorie');
        params.delete('subcategorie');

        setSearchParams(params);
    };

    const handleSortChange = (newSortOrder) => {
        setSortOrder(newSortOrder);
        const params = new URLSearchParams(searchParams);
        params.set('sort', newSortOrder);
        setSearchParams(params);
    };

    return (
        <Layout>
            <>
                <div className="page_title">Produse {tip_produs.charAt(0).toUpperCase() + tip_produs.slice(1)}</div>
                <div className={styles.productLayout}>
                    <div>
                        <div className={styles.productFiltres}>
                            <div className={styles.searchContent}>
                                <div className={styles.searchContentText}>Sorteaza produse dupa</div>
                                <div className={styles.catContainer}>
                                    <div 
                                        className={`${styles.individualCat} ${sortOrder === 'asc' ? styles.selected : ''}`}
                                        onClick={() => handleSortChange('asc')}
                                    >
                                        <div className={styles.checkBox}>
                                            {sortOrder === 'asc' && (
                                                <svg className="checkmark" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                </svg>
                                            )}
                                        </div>
                                        <div className={styles.categoryName}>Pret Crescator</div>
                                    </div>
                                    <div 
                                        className={`${styles.individualCat} ${sortOrder === 'desc' ? styles.selected : ''}`}
                                        onClick={() => handleSortChange('desc')}
                                    >
                                        <div className={styles.checkBox}>
                                            {sortOrder === 'desc' && (
                                                <svg className="checkmark" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                </svg>
                                            )}
                                        </div>
                                        <div className={styles.categoryName}>Pret Descrescator</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.searchContent}>
                                <div className={styles.searchContentText}>Particularitati Produs</div>
                                <div className={styles.catContainer}>
                                    <div className={styles.individualCat} onClick={handleOfertaClick}>
                                        <div className={styles.checkBox}>
                                            {oferta && (
                                                <svg className="checkmark" viewBox="0 0 24 24">
                                                    <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                </svg>
                                            )}
                                        </div>
                                        <div className={styles.categoryName}>Produse la Oferta</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.searchContent}>
                                {beCategories.length > 0 && <div className={styles.searchContentText}>Brand</div>}
                                <div className={styles.catContainer}>
                                    {beCategories.map((item) => (
                                        <div
                                            key={item.id}
                                            className={`${styles.individualCat} ${selectedCategories.includes(item.id.toString()) ? styles.selected : ''}`}
                                            onClick={() => handleCategoryClick(item.id)}
                                        >
                                            <div className={styles.checkBox}>
                                                {selectedCategories.includes(item.id.toString()) && (
                                                    <svg className="checkmark" viewBox="0 0 24 24">
                                                        <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                    </svg>
                                                )}
                                            </div>
                                            <div className={styles.categoryName}>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.searchContent}>
                                {beSubcategories.length > 0 && (
                                    <>
                                        <div className={styles.searchContentText}>Categorii Produse</div>
                                        <div className={styles.catContainer}>
                                            {beSubcategories.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className={`${styles.individualCat} ${selectedSubcategories.includes(item.id.toString()) ? styles.selected : ''}`}
                                                    onClick={() => handleSubcategoryClick(item.id)}
                                                >
                                                    <div className={styles.checkBox}>
                                                        {selectedSubcategories.includes(item.id.toString()) && (
                                                            <svg className="checkmark" viewBox="0 0 24 24">
                                                                <path d="M20.285 6.707a1 1 0 00-1.415 0L10 15.578 5.132 10.71a1 1 0 10-1.414 1.415l5.657 5.656a1 1 0 001.414 0l9.495-9.495a1 1 0 000-1.415z" />
                                                            </svg>
                                                        )}
                                                    </div>
                                                    <div className={styles.categoryName}>{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {products.length > 0 ? (
                        <div className={styles.productContainer}>
                            {products.map((product) => (
                                <Product key={product.id} product={product} />
                            ))}
                        </div>
                    ) : (
                        <div className={styles.emptyBasketContainer}>
                            <div className={styles.individualEmptyBasket}>
                                Din pacate, produsele {tip_produs.charAt(0).toUpperCase() + tip_produs.slice(1)} nu sunt disponibile.
                            </div>
                            <div className={styles.individualEmptyBasket}>
                                Verificati filtrele sau cautati / rasfoiti alte produse si adaugati-le in cos.
                            </div>
                        </div>
                    )}
                </div>
                {products.length > 0 && (
                    <Pagination props={pagination} onPageChange={handlePageChange} />
                )}
            </>
        </Layout>
    );
};

export default Produse;