// Modal.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUiModal } from '../../features/ui/uiSlice';
import styles from './Modal.module.css'

const Modal = () => {
    const dispatch = useDispatch();
    const uiModal = useSelector(state => state.ui.uiModal);
    const modalMessageFromRedux = useSelector(state => state.ui.modalMessage); // Get modal message from Redux state

    const closeModal = () => {
        dispatch(setUiModal(false)); // Dispatch action to close modal
    };

    return uiModal ? (
        <div className={styles.modalBackdrop}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <h2>{modalMessageFromRedux.modalTitle}</h2>
            <button className={styles.closeButton} onClick={closeModal}>
              &times;
            </button>
          </div>
          <div className={styles.modalBody}>
            <p>{modalMessageFromRedux.modalMessage}</p>
          </div>
          <div className={styles.modalFooter}>
            <button className={styles.okButton} onClick={closeModal}>
              OK
            </button>
          </div>
        </div>
      </div>
    ) : null;
};

export default Modal;