// src/pages/Login/Login.js

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import showModal from '../../utils/modalFunction';
import styles from './Login.module.css';
import Modal from '../../components/Modal/Modal';
import { useNavigate } from 'react-router-dom';

const SetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');


    const handlePasswordSubmit = async (event) => {
        // event.preventDefault();
        try {
            if (password.length < 8) {
                showModal(dispatch, 'Atentie!', 'Parola trebuie sa contina minim 9 caractere.')
            }
            if (password !== repassword) {
                showModal(dispatch, 'Atentie!', 'Parolele nu coincid. Asigurati-va ca ambele campuri contin aceeasi parola.')
            }
        } catch (err) {
            showModal(dispatch, 'Eroare!', err)
        }
        // try {
        //     const response = await fetch('http://localhost:3000/api/clienti/login', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ email, password }),
        //         credentials: 'include',
        //     });

        //     if (response.ok) {
        //         const res = await response.json();
        //         dispatch(setUserDetails(res.user));
        //         dispatch(setUserStatus(true));
        //     } else {
        //         const errorData = await response.json();
        //         showModal(dispatch, 'Eroare!', errorData.errors[0].message);
        //     }
        // } catch (error) {
        //     console.error('Error during login:', error);
        // }
    };


    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginImage}></div>
            <Modal className={styles.modalUi} />

            <div className={styles.loginWhiteContainer}>
                <p style={{ marginBottom: '1rem' }}>Setare parola</p>
                <p style={{ textAlign: 'center', fontSize: '14px' }}>Introduceti noua parola care va fi asociata contului dumneavoastra de client. </p>
                <div style={{ marginTop: '0px' }} className={styles.loginForm}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }} className={styles.formGroup}>
                        <input
                            type="password"
                            id="password1"
                            name="forgotPassword1"
                            onChange={(event) => { setPassword(event.target.value) }}
                            placeholder='Introduceti parola'
                            className={styles.inputField}
                        />
                        <input
                            type="password"
                            id="password2"
                            name="forgotPassword2"
                            onChange={(event) => { setRePassword(event.target.value) }}
                            placeholder='Reintroduceti parola'
                            className={styles.inputField}
                        />
                    </div>
                    <button type="submit" className={styles.loginButton} onClick={() => { handlePasswordSubmit() }}>
                        <span>Setare Parola</span>
                    </button>

                </div>
            </div>
        </div>
    );
};

export default SetPassword;
