import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.css'

const Breadcrumbs = ({ category, subcategory, product }) => {
  return (
    <nav aria-label="Breadcrumb" className={styles.bigBread}>
      <ol className={styles.breadcrumbs}>
        <li>
          <Link to="/">Acasa</Link>
        </li>
        <li>
          <Link to={`/produse/${product.tipul_produsului}?categorie=${category.id}`}>{category.name}</Link>
        </li>
        <li>
          <Link to={`/produse/${product.tipul_produsului}?categorie=${category.id}&subcategorie=${subcategory.id}`}>{subcategory.name}</Link>
        </li>
        <li aria-current="page">{product.name}</li>
      </ol>
    </nav>
  );
};

export default Breadcrumbs;