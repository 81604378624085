import React, { useState, useCallback, useRef, useEffect } from 'react';
import styles from './Searchbar.module.css';
import { Icon } from '@iconify/react';
import { useDebounce } from '../../hooks/debounce';
import { fetchData } from '../../utils/fetchData';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const Searchbar = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);

    const searchProducts = useCallback((query) => {
        setIsLoading(true);
        fetchData(`/produse/search?q=${query}`, dispatch)
            .then(res => {
                setProducts(res.docs);
                setShowDropdown(true);
            })
            .catch(err => console.error('Error fetching products:', err))
            .finally(() => setIsLoading(false));
    }, [dispatch]);

    const debouncedSearch = useDebounce(searchProducts, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 0) {
            debouncedSearch(query);
        } else {
            setProducts([]);
            setShowDropdown(false);
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchQuery.length > 0) {
            searchProducts(searchQuery);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.searchContainer}>
            <form className={styles.searchForm} onSubmit={handleSearchSubmit}>
                <input
                    type="text"
                    placeholder="Cauta produs..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className={styles.searchInput}
                />
                <button type="submit" className={styles.searchButton}>
                    <Icon icon="gridicons:search" color='white' inline='true' height='16px' />
                </button>
            </form>
            {showDropdown && (
                <div className={styles.dropdown} ref={dropdownRef}>
                    {isLoading ? (
                        <div className={styles.dropdownItem}>Asteptati...</div>
                    ) : products.length > 0 ? (
                        products.map((product) => (
                            <Link key={product.id} to={`/produs/${product?.id}`} className={styles.productTitleLink} onClick={() => { setShowDropdown(false); setSearchQuery('') }}>
                                <div key={product.id} className={styles.dropdownItem}>
                                    {product.nume_produs}
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className={styles.dropdownItem}>Produsul cautat nu exista</div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Searchbar;