import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addItemAsync } from '../../features/basket/basketSlice';
import styles from './Product.module.css';
import QuantitySelector from './QuantitySelector';
import { setShowComponent } from '../../features/ui/uiSlice';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { showLoading, hideLoading } from '../../features/ui/uiSlice';
import AddedToCartModal from '../Modal/AddedToCartModal'

const Product = ({ product }) => {
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();
    const appUrl = process.env.REACT_APP_URL;
    const [showAddedToCart, setShowAddedToCart] = useState(false);

    const handleAddToBasket = () => {
        if (quantity > 0) {
            dispatch(showLoading());
            dispatch(addItemAsync({ productId: product.id, quantity }))
                .then((action) => {
                    if (addItemAsync.fulfilled.match(action)) {
                        // Action fulfilled
                        setQuantity(1);
                        dispatch(setShowComponent(true));
                    }
                })
                .catch((error) => {
                    console.error('Unexpected error:', error);
                })
                .finally(() => {
                    dispatch(hideLoading());
                });
        }
    };

    const handleQuantityChange = (newQuantity) => {
        setQuantity(newQuantity);
    };

    return (
        <div className={styles.productContainer}>
            {product?.tip_oferta !== 'none' && (
                <div className={styles.banderoleContainer}>
                    <div className={styles.banderole}>
                        Oferta %
                    </div>
                </div>
            )}
            <div className={styles.productImageContainer}>
                <Link to={`/produs/${product?.id}`} className={`${styles.productImageContainer}`}>
                    <img className={styles.productPicture} src={`${appUrl}${product?.imagine?.url}`} alt={product?.nume_produs} />
                </Link>

            </div>
            <Link to={`/produs/${product?.id}`} className={styles.productTitleLink}>
                <div className={`${styles.productTitle} ${styles.productPadding}`}>
                    {product?.nume_produs}, {product?.mod_comercializare}{product?.gramaj ? `, ${product?.gramaj} kg` : null}
                </div>
            </Link>
            {product?.pret_inainte_de_reducere && (
                <div className={`${styles.productDiscountContainer} ${styles.productPadding}`}>
                    <div className={styles.productInitialPrice}>{product?.pret_inainte_de_reducere} €</div>
                    <div className={styles.productReducedPercentage}>
                        -{Math.round(((product?.pret_inainte_de_reducere - product?.pret_final) / product?.pret_inainte_de_reducere) * 100)}%
                    </div>
                </div>
            )}
            <div className={styles.insideProductContainer}>
                <div className={styles.productPadding}>
                    Cod: {product?.cod_produs_intern}
                </div>
                <div className={`${styles.productPrice} ${styles.productPadding}`}>
                    {product?.pret_final.toString().replace('.', ',')} €
                </div>
                <div className={styles.productPadding}>
                    {(product?.pret_final / (1 + (product?.tva / 100))).toFixed(2).toString().replace('.', ',')} € + {product?.tva}% VAT
                </div>
            </div>
            <div style={{ width: '100%', marginTop: 'auto' }}>
                <div >
                    <QuantitySelector
                        productId={product?.id}
                        quantity={quantity}
                        handleQuantityChange={handleQuantityChange}
                        context="productCard"
                    />
                </div>
                <button onClick={() => {handleAddToBasket(); setShowAddedToCart(true)}} className={styles.productBasket}>
                    <AddedToCartModal
                    isVisible={showAddedToCart} 
                    onHide={() => setShowAddedToCart(false)}
                    />
                </button>
            </div>

        </div>
    );
};

export default Product;
