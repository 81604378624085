export const getSubCategoriesByCategoryId = `
query GetSubcategoriesByCategory($categoryIds: [JSON]) {
  Subcategories(
    where: {
      categorie: {
        in: $categoryIds
      }
    },
    sort: "name",
    limit: 0
  ) {
    docs {
      id
      name
    }
  }
}
`


export const getAllCategories = `
query getAllCategories{
  Categories{
    docs{
 			id,
      name
    },
    page,
    totalPages
  }
}
`