import styles from '../../pages/Account/Account.module.css';

const BillingDetails = ({ billing }) => {
    return (
        <div className={styles.contentContainer}>
            <div className={styles.sectionTitle}>Detalii Facturare</div>
            <div className={styles.accountDetailsContainer}>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Nume
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.nume || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Prenume
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.prenume || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Adresa
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.adresa || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Oras
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.oras || 'N/A'}
                    </div>
                </div>
            
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Cod Postal
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.cod_postal || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Banca
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.banca || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        IBAN
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.iban || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        CUI
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.cui || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Reg Com
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {billing?.reg_com || 'N/A'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingDetails;