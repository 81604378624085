import styles from '../../pages/Account/Account.module.css';


const ClientInfo = ({ client }) => {
    return (
        <div className={styles.contentContainer}>
            <div className={styles.sectionTitle}>Informatii client</div>
            <div className={styles.accountDetailsContainer}>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Companie
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.companie || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Email
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.email || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Tier
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.tier || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Cod Client
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {client?.cod_client || 'N/A'}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientInfo;