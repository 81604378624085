import React from 'react';
import serialize from './RichTextRenderer';

const RichText = ({ content }) => {
  if (!content) {
    return null;
  }

  return <div className="rich-text">{serialize(content)}</div>;
};

export default RichText;