import styles from '../../pages/Account/Account.module.css';



const DeliveryDetails = ({ livrare }) => {
    return (
        <div className={styles.contentContainer}>
            <div className={styles.sectionTitle}>Detalii Livrare</div>
            <div className={styles.accountDetailsContainer}>

                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Adresa
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {livrare?.adresa || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Oras
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {livrare?.oras || 'N/A'}
                    </div>
                </div>
                <div className={styles.sectionContainer}>
                    <div className={styles.sectionContainerTitle}>
                        Cod Postal
                    </div>
                    <div className={styles.sectionContainerContent}>
                        {livrare?.cod_postal || 'N/A'}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DeliveryDetails;