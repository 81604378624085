import React, { Fragment } from "react";
import escapeHTML from "escape-html";

const isText = (node) => {
  return typeof node.text !== 'undefined';
};

const serialize = (children) => {
  return children.map((node, i) => {
    if (isText(node)) {
      let text = node.text;

      // Handle newline characters
      if (text.includes('\n')) {
        return text.split('\n').map((line, index) => (
          <Fragment key={`${i}-${index}`}>
            {index > 0 && <br />}
            {renderFormattedText(line, node)}
          </Fragment>
        ));
      }

      return <Fragment key={i}>{renderFormattedText(text, node)}</Fragment>;
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case "h1":
        return <h1 key={i}>{serialize(node.children)}</h1>;
      case "h2":
        return <h2 key={i}>{serialize(node.children)}</h2>;
      case "h3":
        return <h3 key={i}>{serialize(node.children)}</h3>;
      case "h4":
        return <h4 key={i}>{serialize(node.children)}</h4>;
      case "h5":
        return <h5 key={i}>{serialize(node.children)}</h5>;
      case "h6":
        return <h6 key={i}>{serialize(node.children)}</h6>;
      case "blockquote":
        return <blockquote key={i}>{serialize(node.children)}</blockquote>;
      case "ul":
        return <ul key={i}>{serialize(node.children)}</ul>;
      case "ol":
        return <ol key={i}>{serialize(node.children)}</ol>;
      case "li":
        return <li key={i}>{serialize(node.children)}</li>;
      case "link":
        return (
          <a href={escapeHTML(node.url)} key={i}>
            {serialize(node.children)}
          </a>
        );
      default:
        return <p key={i}>{serialize(node.children)}</p>;
    }
  });
};

const renderFormattedText = (text, node) => {
  let formattedText = <span dangerouslySetInnerHTML={{ __html: escapeHTML(text) }} />;

  if (node.bold) {
    formattedText = <strong>{formattedText}</strong>;
  }

  if (node.code) {
    formattedText = <code>{formattedText}</code>;
  }

  if (node.italic) {
    formattedText = <em>{formattedText}</em>;
  }

  return formattedText;
};

export default serialize;