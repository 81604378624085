import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import styles from './Layout.module.css'; // Import CSS module
import Modal from '../Modal/Modal';
import LoadingModal from '../Modal/LoadingModal';

const Layout = ({ children }) => {
    return (
        <div className={styles.layoutContainer}>
            <LoadingModal />
            <Modal className={styles.modalUi} />
            <Header />
            <main className={styles.contentContainer}>
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
