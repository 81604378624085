import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { fetchData } from '../utils/fetchData';
import { useDispatch } from 'react-redux';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css';

// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const MySwiper = () => {
  const dispatch = useDispatch();
  const appUrl = process.env.REACT_APP_URL;
  const [swiperImage, setSwiperImage] = React.useState([]);


  React.useEffect(() => {
    fetchData(`/banner`, dispatch)
      .then(data => {
        setSwiperImage(data?.docs)
      })
  }, [])

  const navigate = useNavigate();

  const handleRedirect = (url) => {
    navigate(url);
  };

  const params = {
    spaceBetween: 30,
    loop: true,
    navigation: true,
    speed: 1200,
    pagination: { clickable: true },
    mousewheel: false, // Disable mouse wheel
    draggable: false,
    allowTouchMove: false,
  }
  return (
    swiperImage?.length > 0 ? (
      <Swiper
        {...params}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        {swiperImage.map(image => (
          <SwiperSlide key={image.id}>
            <img
              style={{ cursor: 'pointer' }}
              alt={image.nume}
              src={`${appUrl}${image.url}`}
              onClick={() => handleRedirect(image.text)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    ) : null
  )
}

export default MySwiper;
