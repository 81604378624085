

import { logOutUser } from "../features/user/userSlice";
const apiUrl = process.env.REACT_APP_API_URL; // Access the base API URL


const fetchData = async (endpoint, dispatch) => {
    try {
        const response = await fetch(apiUrl + endpoint, {
            credentials: 'include'
        });
        if (!response.ok) {
            // console.log('res: ', response)

            const errorData = await response.json();
            console.log('errror: ', errorData);
            if (dispatch) {
                dispatch(logOutUser());
            }
            return { error: errorData.message || 'Failed to fetch data, user logged out.' };
        }

        const data = await response.json();
        return data;
    } catch (err) {
        console.log('There was a problem with the fetch. Error: ', err);
        return { error: 'Network error' };
    }
};

const sendData = async (endpoint, data = null, dispatch) => {
    try {
        const response = await fetch(apiUrl + endpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data
        });

        if (!response.ok) {
            const errorData = await response.json();
            return { error: errorData.message || 'Failed to send data.' };
        }

        const responseData = await response.json();
        return responseData;
    } catch (err) {
        console.log('There was a problem with the post. Error: ', err);
        return { error: 'Network error' };
    }
};


const getDataGraphQL = async (query, variables) => {
    try {
        const response = await fetch(apiUrl + '/graphql', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query, variables })
        });

        const data = await response.json();
        return data;
    } catch (err) {
        console.log('There was a problem with the fetch. Error: ', err);
    }
}

export { fetchData, sendData, getDataGraphQL };