import styles from './LoadingModal.module.css'
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GridLoader from "react-spinners/GridLoader";

const LoadingModal = () => {
    const isLoadingFromRedux = useSelector((state) => state.ui.loadingModal);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        let timeoutId;
        if (isLoadingFromRedux) {
            timeoutId = setTimeout(() => {
                setShowLoading(true);
            }, 500);
        } else {
            setShowLoading(false);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isLoadingFromRedux]);

    if (!showLoading) return null;

    return (
        <div className={styles.loadingModal}>
            <GridLoader
                color={'#C52E3F'}
                loading={showLoading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
                speedMultiplier={0.8}
            />
        </div>
    );
}

export default LoadingModal;