import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItemAsync, substractItemAsync, modifyProductQuantityAsync } from '../../features/basket/basketSlice';
import styles from './Product.module.css';
import { hideLoading, showLoading } from '../../features/ui/uiSlice';
import { useDebounce } from '../../hooks/debounce';

const QuantitySelector = ({ productId, quantity = 0, handleQuantityChange = () => { }, context }) => {
    const dispatch = useDispatch();
    const productFromRedux = useSelector(state => state.basket.items[productId]);
    const [localQuantity, setLocalQuantity] = useState(quantity || productFromRedux?.quantity || 1);
    const [displayQuantity, setDisplayQuantity] = useState(localQuantity);

    const debouncedModifyItemUnit = useDebounce((productId, howMany) => {
        dispatch(modifyProductQuantityAsync({ productId, howMany }))
            .then(() => {
                setDisplayQuantity(howMany + (productFromRedux?.free_units));
            });
    }, 1000);

    useEffect(() => {
        if (context === 'basket' && productFromRedux) {
            setLocalQuantity(productFromRedux.quantity);
            setDisplayQuantity(productFromRedux.quantity );
        } else {
            setLocalQuantity(quantity);
            setDisplayQuantity(quantity);
        }
    }, [context, productFromRedux, quantity]);

    const handleIncrease = () => {
        const newQuantity = localQuantity + 1;
        setLocalQuantity(newQuantity);
        if (context === 'basket') {
            dispatch(showLoading());
            dispatch(addItemAsync({ productId: productId, quantity: 1 }))
                .then(() => {
                    setDisplayQuantity(newQuantity);
                    dispatch(hideLoading());
                });
        } else {
            handleQuantityChange(newQuantity);
            setDisplayQuantity(newQuantity);
        }
    };

    const handleDecrease = () => {
        const newQuantity = localQuantity - 1;
        if (newQuantity > 0) {
            setLocalQuantity(newQuantity);
            if (context === 'basket') {
                dispatch(showLoading());
                dispatch(substractItemAsync({ productId }))
                    .then(() => {
                        setDisplayQuantity(newQuantity);
                        dispatch(hideLoading());
                    });
            } else {
                handleQuantityChange(newQuantity);
                setDisplayQuantity(newQuantity);
            }
        }
    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const inputQuantity = parseInt(inputValue, 10);

        if (inputValue === '' || (!isNaN(inputQuantity) && inputQuantity > 0)) {
            setLocalQuantity(inputValue === '' ? '' : inputQuantity);
            setDisplayQuantity(inputValue === '' ? '' : inputQuantity);

            if (!isNaN(inputQuantity) && inputQuantity > 0) {
                if (context === 'basket') {
                    debouncedModifyItemUnit(productId, inputQuantity);
                } else {
                    handleQuantityChange(inputQuantity);
                }
            }
        }
    };

    return (
        <div className={styles.quantitySelector}>
            <button
                className={`${styles.quantityButton} ${localQuantity > 1 ? styles.green : styles.grey}`}
                onClick={handleDecrease}
                disabled={localQuantity <= 1}
            >
                -
            </button>
            <input
                type="text"
                className={styles.quantityInput}
                value={displayQuantity}
                onChange={handleChange}
            />
            <button
                className={`${styles.quantityButton} ${styles.green}`}
                onClick={handleIncrease}
            >
                +
            </button>
        </div>
    );
};

export default QuantitySelector;