import Layout from '../../components/Layout/Layout';
import React from 'react';
import styles from './Promotions.module.css';
import { Link } from 'react-router-dom';

const Promotions = () => {

    return (
        <Layout>
            <div className={styles.promotionsContainer}>
                <Link style={{ margin: '0px 10px' }} to="/produse/gepeto?oferta=true">
                    <div className={styles.individualPromotionContainer}>
                        <h3>Promotii Gepeto</h3>

                    </div>
                </Link>
                {/* <Link style={{ margin: '0px 10px' }} to="/produse/bacanie?oferta=true">
                    <div className={styles.individualPromotionContainer}>
                        <h3>Promotii Bacanie</h3>
                    </div>
                </Link> */}

            </div>
        </Layout>
    );
};

export default Promotions;