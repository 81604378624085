import Layout from "../../components/Layout/Layout";
import { useParams } from 'react-router-dom';
import React, { useState } from "react";
import styles from './ProductDetail.module.css'
import { getDataGraphQL } from '../../utils/fetchData';
import { fetchProductById } from '../../utils/graphQL/productQueries';
import QuantitySelector from "../../components/Product/QuantitySelector";
import { useDispatch } from "react-redux";
import { addItemAsync } from "../../features/basket/basketSlice";
import { setShowComponent } from "../../features/ui/uiSlice";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { showLoading, hideLoading } from "../../features/ui/uiSlice";
import { Icon } from '@iconify/react';


const ProductDetail = () => {
    const { id } = useParams();
    const [quantity, setQuantity] = useState(1);
    const [product, setProduct] = useState(null);
    const [selectedSection, setSelectedSection] = useState('descriere');
    const appUrl = process.env.REACT_APP_URL;

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(showLoading())
        getDataGraphQL(fetchProductById, { productId: Number(id) }).then((res) => {
            setProduct(res.data.Produse);
            dispatch(hideLoading())
        });
    }, [id]);

    const handleQuantityChange = (remoteState) => {
        setQuantity(remoteState);
    }

    const handleAddToBasket = () => {
        dispatch(showLoading())
        dispatch(addItemAsync({ productId: product.id, quantity }))
            .then(() => {
                dispatch(hideLoading());
                setQuantity(1);
                dispatch(setShowComponent(true));
            })
    };

    return (
        <Layout>
            {product &&
                <>
                    <Breadcrumbs category={product.categorie} product={product} subcategory={product.subcategorie} />
                    <div className={styles.individualProductContainer}>
                        <div className={styles.leftSide}>
                            {product.tip_oferta !== 'none' && (
                                <div className={styles.banderoleContainer}>
                                    <div className={styles.banderole}>
                                        Oferta %
                                    </div>
                                </div>
                            )}
                            <div className={styles.imageContainer}>
                                <img className={styles.productPicture} src={`${appUrl}${product?.imagine?.url}`} alt={product?.title} />
                            </div>

                        </div>
                        <div className={styles.rightSide}>
                            <div className={styles.title}>{product.nume_produs}, {product.mod_comercializare}</div>
                            {product.bucati_pe_bax && <div className={styles.normal}>Bucati pe bax: {product.bucati_pe_bax}</div>}
                            {product.pret_pe_bucata && <div className={styles.normal}>Pret pe bucata: {product.pret_pe_bucata}</div>}
                            {product.gramaj && <div className={styles.normal}>Gramaj: {product.gramaj} kg (Cantitatea este orientativa, +-50g)</div>}
                            <div className={styles.normal}> Pret fara TVA: {(product?.pret_final / (1 + (product?.tva / 100))).toFixed(2).toString()} € + {product?.tva}% VAT</div>
                            <div className={styles.tva}>Pret cu TVA: {product.pret_final} €</div>
                            {
                                product.tip_oferta !== 'none' ?
                                    <div className={styles.oferta}>
                                        <Icon className={styles.rotatableIcon} icon="bxs:offer" color='#C52E3F' inline='true' height='30px' />
                                        <OfferType props={product} />
                                    </div>
                                    :
                                    null
                            }

                            <div className={styles.quantityContainer}>
                                <QuantitySelector productId={id} quantity={quantity} handleQuantityChange={handleQuantityChange} />
                                <button onClick={handleAddToBasket} className={styles.productBasket}>
                                    <span>Adauga in Cos</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.underContainer}>
                        <div className={styles.accountTabs}>
                            <button
                                className={selectedSection === 'descriere' ? styles.active : ''}
                                onClick={() => setSelectedSection('descriere')}
                            >
                                Descriere
                            </button>
                            <button
                                className={selectedSection === 'informatii_nutritionale' ? styles.active : ''}
                                onClick={() => setSelectedSection('informatii_nutritionale')}
                            >
                                Informatii Nutritionale
                            </button>
                            {/* Add more tabs as needed */}
                        </div>
                        <div className={styles.accountDetailsContainer}>
                            {selectedSection === 'descriere' && (
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionContainerContent}>{product.descriere}</div>
                                </div>
                            )}
                            {selectedSection === 'informatii_nutritionale' && (
                                <div className={styles.sectionContainer}>
                                    <div className={styles.sectionContainerContent}>{product.informatii_nutritionale}</div>
                                </div>
                            )}
                            {/* Add more sections as needed */}
                        </div>
                    </div>
                </>
            }
        </Layout>
    );
}

export default ProductDetail;

const OfferType = ({ props }) => {
    const [offerType, setOfferType] = React.useState({});

    React.useEffect(() => {
        if (props.tip_oferta === "cumpara_x_primesti_y_procentaj") {
            setOfferType({
                tip_oferta: 'Discount Progresiv',
                prag_oferta: props.prag_oferta
            });
        } else if (props.tip_oferta === 'flat') {
            setOfferType({
                tip_oferta: 'Discount Liniar',
                prag_oferta: null
            });
        } else if (props.tip_oferta === 'cumpara_x_primesti_y') {
            setOfferType({
                tip_oferta: 'Discount Promotional',
                prag_oferta: props.prag_oferta
            });
        }
    }, [props])

    return (
        <div className={styles.offerTypeContainer}>
            {offerType?.tip_oferta && (
                <div className={styles.offerTypeTitle}>{offerType.tip_oferta}</div>
            )}
            {props.tip_oferta === "cumpara_x_primesti_y_procentaj" && (
                <ul className={styles.offerList}>
                    {offerType?.prag_oferta?.map((oferta, index) => (
                        <li key={index}>
                            La {oferta.prag_cantitate} unitati cumparate, primiti {oferta.valoare_oferta}% reducere.
                        </li>
                    ))}
                </ul>
            )}
            {props.tip_oferta === "cumpara_x_primesti_y" && (
                <ul className={styles.offerList}>
                    {offerType?.prag_oferta?.map((oferta, index) => (
                        <li key={index}>
                            La {oferta.prag_cantitate} unitati cumparate, primiti {oferta.valoare_oferta} gratuit.
                        </li>
                    ))}
                </ul>
            )}
            {props.tip_oferta === "flat" && (
                <div className={styles.flatDiscount}>
                    {props.flat_discount_percentage}% reducere la orice cantitate.
                </div>
            )}
        </div>
    );
}