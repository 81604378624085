import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import styles from './Account.module.css';
import BillingDetails from '../../components/Account/BillingDetails';
import ClientInfo from '../../components/Account/ClientInfo';
import DeliveryDetails from '../../components/Account/DeliveryDetails';
import PastOrders from '../../components/Account/PastOrders';





const Account = () => {

  const userDetails = useSelector(state => state.user.userDetails);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(userDetails.puncte_de_lucru[0]);
  const [selectedSection, setSelectedSection] = useState('comenzi');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <Layout>
      <div className={styles.accountContainer}>
        <div class="page_title" style={{alignSelf:'center'}}>Contul Meu</div>
        <div className={styles.accountTabs}>
          <ul>
            <button className={selectedSection === 'comenzi' ? styles.active : ''} onClick={() => setSelectedSection('comenzi')}>Comenzile mele</button>
            <button className={selectedSection === 'date_livrare' ? styles.active : ''} onClick={() => setSelectedSection('date_livrare')}>Date livrare</button>
            <button className={selectedSection === 'date_facturare' ? styles.active : ''} onClick={() => setSelectedSection('date_facturare')}>Date facturare</button>
            <button className={selectedSection === 'informatii_client' ? styles.active : ''} onClick={() => setSelectedSection('informatii_client')}>Informatii Client</button>
          </ul>
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown}>
              <button onClick={handleToggle} className={styles.dropdownToggle}>
                {selectedOption.denumire}
                <div className={`${styles.arrow} ${isOpen ? styles.open : ''}`}></div>
              </button>
              {isOpen && (
                <ul className={styles.dropdownMenu}>
                  {userDetails.puncte_de_lucru.map((option) => (
                    <li
                      key={option.id}
                      onClick={() => handleOptionClick(option)}
                      className={styles.dropdownMenuItem}
                    >
                      {option.denumire}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {selectedSection === 'comenzi' && <PastOrders />}
        {selectedSection === 'date_livrare' && <DeliveryDetails livrare={selectedOption.detalii_livrare} />}
        {selectedSection === 'date_facturare' && <BillingDetails billing={selectedOption.detalii_facturare} />}
        {selectedSection === 'informatii_client' && <ClientInfo client={userDetails} />}
      </div>
    </Layout>
  );
};

export default Account;
