import React, { useEffect, useRef } from 'react';

const AddedToCartModal = ({ isVisible, onHide }) => {
    const timerRef = useRef(null);

    useEffect(() => {
        if (isVisible) {
            timerRef.current = setTimeout(() => {
                onHide();
            }, 3000);
        }
    }, [isVisible, onHide]);

    return (
        <>
            {isVisible ? (
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <svg style={{marginRight:'5px'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="orange" stroke="black" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-thumbs-up">
                        <path d="M7 10v12"/>
                        <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2a3.13 3.13 0 0 1 3 3.88Z"/>
                    </svg>
                    Produs adaugat cu succes.
                </div>
            ) : (
                <div>Adauga in cos</div>
            )}
        </>
    );
};

export default AddedToCartModal;