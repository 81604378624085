import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showComponent: false,
    uiModal: false,
    modalMessage: {
        modalTitle:'',
        modalMessage:'',
    },
    loadingModal: false
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setShowComponent: (state, action) => {
            state.showComponent = action.payload;
        },
        setUiModal: (state, action) => {
            state.uiModal = action.payload;
        },
        setModalMessage: (state, action) => {
            state.modalMessage = action.payload;
        },
        showLoading: (state)=>{
            state.loadingModal = true;
        },
        hideLoading: (state)=>{
            state.loadingModal = false;
        },
    },
});

export const { setShowComponent, setUiModal, setModalMessage, showLoading, hideLoading } = uiSlice.actions;
export default uiSlice.reducer;