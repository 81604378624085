import React, { useMemo } from "react";
import styles from './Pagination.module.css';
import { Icon } from '@iconify/react';


const Pagination = ({ props, onPageChange }) => {
    const { totalPages, hasNextPage, hasPrevPage, page } = props || {};

    const handlePageChange = (newPage) => {
        onPageChange(newPage);
    };

    React.useEffect(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // for smooth scrolling
              })
    }, [page])

    // Generate an array of page numbers to display
    const pageNumbers = useMemo(() => {
        if (!props) return [];

        const result = [];
        const maxPagesToShow = 1; // Adjust this number to show more or fewer pages

        if (totalPages <= maxPagesToShow * 2) {
            for (let i = 1; i <= totalPages; i++) {
                result.push(i);
            }
        } else {
            result.push(1);

            if (page > maxPagesToShow + 1) {
                result.push('...');
            }

            const startPage = Math.max(2, page - maxPagesToShow);
            const endPage = Math.min(totalPages - 1, page + maxPagesToShow);

            for (let i = startPage; i <= endPage; i++) {
                result.push(i);
            }

            if (page < totalPages - maxPagesToShow) {
                result.push('...');
            }

            result.push(totalPages);
        }

        return result;
    }, [props, page, totalPages]);

    if (!props) return null;

    return (
        <div className={styles.container}>
            <button
                onClick={() => handlePageChange(page - 1)}
                disabled={!hasPrevPage}
                className={styles.pageButton}
            >
                <Icon icon="grommet-icons:previous" color='#C52E3F' inline='true' height='15px' />

            </button>

            {pageNumbers.map((pageNum, index) => (
                <button
                    key={index}
                    onClick={() => typeof pageNum === 'number' && handlePageChange(pageNum)}
                    disabled={pageNum === '...'}
                    className={`${styles.pageButton} ${pageNum === page ? styles.activePage : ''}`}
                >
                    {pageNum}
                </button>
            ))}

            <button
                onClick={() => handlePageChange(page + 1)}
                disabled={!hasNextPage}
                className={styles.pageButton}
            >
                <Icon icon="grommet-icons:next" color='#C52E3F' inline='true' height='15px' />
            </button>
        </div>
    );
};

export default Pagination;
