import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
    categories: []
};

const sortingSlice = createSlice({
    name: 'sorting',
    initialState,
    reducers: {
        manageCategories: (state, action) => {
            const { name } = action.payload.item;
            if (!Array.isArray(state.categories)) {
                state.categories = [];
            }
            const index = state.categories.indexOf(name);
            if (index > -1) {
                state.categories.splice(index, 1);
            } else {
                state.categories.push(name);
            }
        }
    },
});

export const { manageCategories } = sortingSlice.actions;
export default sortingSlice.reducer;