import React from 'react';
import styles from './Footer.module.css'; // Import CSS module
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className={styles.footerColorContainer}>
            <div className={styles.footerContainer}>
                <div className={styles.footerColumn}>
                    <div className={styles.sectionTitle}>Produse</div>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to='/produse/gepeto'>
                        <div className={styles.individualElement}>Produse Gepeto</div>
                    </Link>
                    {/* <Link style={{ textDecoration: 'none', color: 'black' }} to='/produse/bacanie'>
                        <div className={styles.individualElement}>Produse Bacanie</div>
                    </Link> */}

                </div>
                <div className={styles.footerColumn}>
                    <div className={styles.sectionTitle}>Sesizari</div>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to='/contact'>
                        <div className={styles.individualElement}>Contact</div>
                    </Link>
                    <div className={styles.individualElement}>
                        <div className={styles.svgContainer}>
                            <div className={styles.imageContainer}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/anpc-sal.svg`}
                                    className={styles.smallSvg}
                                    alt="ANPC SAL"
                                    onClick={() => { window.open('https://anpc.ro/ce-este-sal/', '_blank') }}
                                />
                            </div>
                            <div className={styles.imageContainer}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/anpc-sol.svg`}
                                    className={styles.smallSvg}
                                    alt="ANPC SOL"
                                    onClick={() => { window.open('https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO/', '_blank') }}
                                />
                            </div>
                        </div>
                        <div className={styles.svgContainer}>
                            <div className={styles.imageContainer}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/ifs.png`}
                                    className={styles.smallSvg}
                                    alt="IFS"
                                    onClick={() => { window.open('https://www.ifs-certification.com/en/', '_blank') }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footerColumn}>
                    <div className={styles.sectionTitle}>Informatii</div>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to='/informatii/informatii-legale'>
                        <div className={styles.individualElement}>Informatii legale</div>
                    </Link>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to='/informatii/termeni-si-conditii'>
                        <div className={styles.individualElement}>Termeni si conditii</div>
                    </Link>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to='/informatii/politica-confidentialitate'>
                        <div className={styles.individualElement}>Politica de Confidentialitate si GDPR</div>
                    </Link>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to='/informatii/politica-cookies'>
                        <div className={styles.individualElement}>Politica de cookies</div>
                    </Link>
                </div>
            </div>
            <div className={styles.developedBy}>Gepeto ©1998 – 2024 Toate drepturile rezervate</div>
            <a
                className={styles.developedBy}
                style={{
                    color: '#C52E3F',
                    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)',
                    textDecoration: 'none',
                    transition: 'text-shadow 0.3s ease'
                }}
                href="https://pulsarlab.ro"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={(e) => e.target.style.textShadow = '1px 1px 2px rgba(0, 0, 0, 0.2)'}
                onMouseLeave={(e) => e.target.style.textShadow = '1px 1px 1px rgba(0, 0, 0, 0.1)'}
            >
                Developed by PulsarLAB©
            </a>
        </footer>
    );
};

export default Footer;
