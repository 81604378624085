import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Acasa from './pages/Home/Acasa';
import Checkout from './pages/Checkout/Checkout';
import Produse from './pages/Produse/Produse';
import ProductDetail from './pages/ProductDetail/ProductDetail';
import Basket from './pages//Basket/Basket';
import Login from './pages/Login/Login';
import { useSelector } from 'react-redux';
import Account from './pages/Account/Account';
import React from 'react';
import Test from '../src/pages/Test'
import Cookie from './pages/StaticTexts/Cookie'
import Contact from './pages/Contact/Contact';
import Promotions from './pages/Promotions/Promotions';
import SetPassword from './pages/Login/SetPassword';

function App() {
  const isUserConnected = useSelector(state => state.user.isUserConnected);

  const ProtectedRoute = ({ children }) => {
    if (!isUserConnected) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={isUserConnected ? <Navigate to="/" replace /> : <Login />} />
        <Route path="/resetare_parola/:token" element={<SetPassword />} />
        <Route path="/informatii/:nume" element={<ProtectedRoute><Cookie /></ProtectedRoute>} />
        <Route path="/" element={<ProtectedRoute><Acasa /></ProtectedRoute>} />
        <Route path="/produse/:tip_produs" element={<ProtectedRoute><Produse /></ProtectedRoute>} />
        <Route path="/promotii" element={<ProtectedRoute><Promotions /></ProtectedRoute>} />
        <Route path="/cos" element={<ProtectedRoute><Basket /></ProtectedRoute>} />
        <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
        <Route path="/test" element={<ProtectedRoute><Test /></ProtectedRoute>} />
        <Route path="/cont" element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path="/produs/:id" element={<ProtectedRoute><ProductDetail /></ProtectedRoute>} />
        <Route path="/contact" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;