import React from 'react';
import { fetchData } from '../../utils/fetchData';
import Layout from '../../components/Layout/Layout';
import RichText from '../../components/RichTextRenderer/RichText';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../../features/ui/uiSlice';

const StaticTexts = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [data, setData] = React.useState(null);
    const staticText = location.pathname.split('/')[location.pathname.split('/').length - 1]


    React.useEffect(() => {
        dispatch(showLoading())
        fetchData(`/texte-statice?where[name][equals]=${staticText}`)
            .then((res) => { setData(res.docs[0].content); dispatch(hideLoading()) }).catch(err => console.log('erro: ', err))
    }, [staticText])


    return (
        <Layout>
            {
                data ? <RichText content={data} /> : null
            }
        </Layout>
    )
}

export default StaticTexts